
<div class="login-container">
  <div class="login">
    <div class="jumbotron text-center">
      <h1>{{ appName }}</h1>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 mx-auto">
        <form [formGroup]="loginForm"
              class="__entityForm">
          <kendo-floatinglabel text="Nombre de Usuario">
            <input kendoTextBox autocomplete="off"
                   class="form-control"
                   name="User"
                   #username
                   [ngClass]="{'is-invalid': loginForm.get('User').errors && loginForm.get('User').touched}"
                   type="text"
                   placeholder="Nombre de Usuario"
                   formControlName="User" />
          </kendo-floatinglabel>
          <br />
          <kendo-floatinglabel text="Contraseña">
            <input kendoTextBox autocomplete="off"
                   class="form-control"
                   name="Password"
                   [ngClass]="{'is-invalid': loginForm.get('Password').errors && loginForm.get('Password').touched}"
                   type="password"
                   placeholder="Contraseña"
                   formControlName="Password" />
          </kendo-floatinglabel>
          <br /><br />
          <div class="text-center">
            <button KendoButton
                    class="btn btn-primary btn-block"
                    type="submit"
                    [disabled]="!isValidForm() || disableLoginButton"
                    (click)="login()">
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { TicketVM, OperationResult, TicketGenerator } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class TicketGeneratorService {

  apiUrl: string = `${environment.apiUrl}/Ticket`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  getSaleAndTicketReference(shiftId: string) {
    const uri = `${this.apiUrl}/GetSaleAndTicketReference?shiftId=${shiftId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<TicketVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getUserSaleAndTicketReference(shiftId: string, userId: string) {
    const uri = `${this.apiUrl}/GetUserSaleAndTicketReference?shiftId=${shiftId}&userId=${userId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<TicketVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  saveTicket(ticketId: number) {
    let ticket: TicketGenerator = {
      Id: ticketId
    };
    const uri = `${this.apiUrl}/SaveTicket`;
    return this.http.put(uri, ticket)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  deleteTicket(ticketId: number) {
    const uri = `${this.apiUrl}/DeleteTicket?ticketId=${ticketId}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

import { SearchPipe } from './pipes/search.pipe';
import { NavigationShortcutsComponent } from './shortcuts/navigation-shortcuts/navigation-shortcuts.component';
import { SalesShortcutsComponent } from './shortcuts/sales-shortcuts/sales-shortcuts.component';
import { LoadingComponent } from './components/loading/loading.component';
import { GenericGridComponent } from './components/generic-grid/generic-grid.component';
import { UnitSelectorComponent } from './components/unit-selector/unit-selector.component';
import { CategorySelectorComponent } from './components/category-selector/category-selector.component';
import { StateAndCitySelectorComponent } from './components/state-and-city-selector/state-and-city-selector.component';
import { ProductCategorySelectorComponent } from './components/product-category-selector/product-category-selector.component';
import { PaymentMethodSelectorComponent } from './components/payment-method-selector/payment-method-selector.component';
import { CustomerSelectorComponent } from './components/customer-selector/customer-selector.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { ListDetailsComponent } from './components/list-details/list-details.component';
import { MultiplePayMethodFormComponent } from './components/multiple-pay-method-form/multiple-pay-method-form.component';
import { UnpaidSalesFromCustomerSelectorComponent } from './components/unpaid-sales-from-customer-selector/unpaid-sales-from-customer-selector.component';
import { RolSelectorComponent } from './components/rol-selector/rol-selector.component';
import { HeaderDetailsComponent } from './components/header-details/header-details.component';
import { ProductsListSelectorComponent } from './components/products-list-selector/products-list-selector.component';
import { CashClosingDetailsComponent } from './components/cash-closing-details/cash-closing-details.component';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { AuthorizationDialogComponent } from './components/authorization-dialog/authorization-dialog.component';
import { RouteSelectorComponent } from './components/route-selector/route-selector.component';
import { MultipleRolSelectorComponent } from './components/multiple-rol-selector/multiple-rol-selector.component';
import { MultiplePaymentMethodComponent } from './components/multiple-payment-method/multiple-payment-method.component';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog/delete-confirm-dialog.component';
import { ShiftSelectorDialogComponent } from './components/shift-selector-dialog/shift-selector-dialog.component';
import { AuthorizationWindowComponent } from './components/authorization-window/authorization-window.component';
import { ClosedShiftsSelectorDialogComponent } from './components/closed-shifts-selector-dialog/closed-shifts-selector-dialog.component';
import { CashCloseComponent } from './components/cash-close/cash-close.component';
import { SavedTicketsComponent } from './components/saved-tickets/saved-tickets.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { TicketSizeSelectorComponent } from './components/ticket-size-selector/ticket-size-selector.component';
import { CurrencySelectorComponent } from './components/currency-selector/currency-selector.component';
import { ShortcutsHelpDialogComponent } from './shortcuts/shortcuts-help-dialog/shortcuts-help-dialog.component';
import { PaymentMethodButtonSelectorComponent } from './components/payment-method-button-selector/payment-method-button-selector.component';
import { MultipleUnpaidSalesFromCustomerSelectorComponent } from './components/multiple-unpaid-sales-from-customer-selector/multiple-unpaid-sales-from-customer-selector.component';
import { SearchProductComponent } from './components/search-product/search-product.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { FilteredGridComponent } from './components/filtered-grid/filtered-grid.component';
import { ReprintTicketComponent } from './components/reprint-ticket/reprint-ticket.component';
import { FilterShiftsByDatePipe } from './pipes/filter-shifts-by-date.pipe';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';

@NgModule({
  declarations: [
    //#region common components
    LoadingComponent,
    GenericGridComponent,
    UnitSelectorComponent,
    CategorySelectorComponent,
    StateAndCitySelectorComponent,
    ProductCategorySelectorComponent,
    PaymentMethodSelectorComponent,
    NavigationShortcutsComponent,
    SalesShortcutsComponent,
    TicketSizeSelectorComponent,
    CustomerSelectorComponent,
    DateSelectorComponent,
    ListDetailsComponent,
    MultiplePayMethodFormComponent,
    UnpaidSalesFromCustomerSelectorComponent,
    RolSelectorComponent,
    HeaderDetailsComponent,
    ProductsListSelectorComponent,
    CashClosingDetailsComponent,
    LogoUploadComponent,
    AuthorizationDialogComponent,
    RouteSelectorComponent,
    MultipleRolSelectorComponent,
    ProductSearchComponent,
    DateRangeSelectorComponent,
    MultiplePaymentMethodComponent,
    DeleteConfirmDialogComponent,
    ShiftSelectorDialogComponent,
    AuthorizationWindowComponent,
    SearchPipe,
    ClosedShiftsSelectorDialogComponent,
    CashCloseComponent,
    SavedTicketsComponent,
    CurrencySelectorComponent,
    ShortcutsHelpDialogComponent,
    PaymentMethodButtonSelectorComponent,
    MultipleUnpaidSalesFromCustomerSelectorComponent,
    SearchProductComponent,
    SearchInputComponent,
    UserInfoComponent,
    FilteredGridComponent,
    ReprintTicketComponent,
    FilterShiftsByDatePipe,
    GenericDialogComponent,

    //#endregion
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    //#region common components
    LoadingComponent,
    GenericGridComponent,
    UnitSelectorComponent,
    CategorySelectorComponent,
    StateAndCitySelectorComponent,
    ProductCategorySelectorComponent,
    PaymentMethodSelectorComponent,
    NavigationShortcutsComponent,
    SalesShortcutsComponent,
    TicketSizeSelectorComponent,
    CustomerSelectorComponent,
    DateSelectorComponent,
    ListDetailsComponent,
    MultiplePayMethodFormComponent,
    UnpaidSalesFromCustomerSelectorComponent,
    RolSelectorComponent,
    HeaderDetailsComponent,
    ProductsListSelectorComponent,
    CashClosingDetailsComponent,
    LogoUploadComponent,
    AuthorizationDialogComponent,
    RouteSelectorComponent,
    MultipleRolSelectorComponent,
    ProductSearchComponent,
    DateRangeSelectorComponent,
    MultiplePaymentMethodComponent,
    DeleteConfirmDialogComponent,
    ShiftSelectorDialogComponent,
    AuthorizationWindowComponent,
    SearchPipe,
    ClosedShiftsSelectorDialogComponent,
    CashCloseComponent,
    SavedTicketsComponent,
    ShortcutsHelpDialogComponent,
    PaymentMethodButtonSelectorComponent,
    CurrencySelectorComponent,
    MultipleUnpaidSalesFromCustomerSelectorComponent,
    SearchProductComponent,
    FilteredGridComponent,
    UserInfoComponent,
    ReprintTicketComponent,
    FilterShiftsByDatePipe,
    GenericDialogComponent,
    //#endregion
  ],
})
export class CommonComponentsModule { }


<kendo-dialog title="{{title}}"
              *ngIf="showDialog"
              (close)="close()"
              [minWidth]="minWidth"
              [width]="width">

  <b>{{message}}</b>

  <ng-container *ngIf="description!==null">
    <p class="mt-3 ml-3">{{description}}</p>
  </ng-container>

  <kendo-dialog-actions>
    <button class="btn btn-primary btn-block"
            (click)="close()">
      Aceptar
    </button>
  </kendo-dialog-actions>

</kendo-dialog>

import { Injectable } from '@angular/core';
import { AlertifyService } from '../common/services/alertify.service';
import { OperationResult } from '../models/view-models';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private alertify: AlertifyService) { }

  logError(err: OperationResult<any>) {
    console.clear();
    //console.log("API Error: ", err);
    switch (err.StatusCode) {
      case 404: console.log("No hay registros");
        break;
      case 409: if (err.Details.length > 0) {
        err.Details.forEach(item => {
          this.alertify.warning(item.Message);
        });
      }
        break;
      default: this.alertify.error("Ocurrió un error en el servidor");
        err.Details.forEach(item => {
          console.log('ERROR: ', item.Message);
        });
        break;
    }
  }
}

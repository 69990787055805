import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tick } from '@angular/core/testing';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { LocalData } from '../models/LocalData';
import { Ticket } from '../models/LocalModels';
import { OperationResult, TicketVM } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  apiUrl: string = `${environment.apiUrl}/Ticket`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) {
    if (localStorage.getItem(LocalData.TICKETS) == null) {
      let _tickets: Ticket[] = [];
      this.setTickets(_tickets);
    }
  }

  getClosedTickets() {
    const uri = `${this.apiUrl}/GetClosedTickets`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<TicketVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getTicketById(id: number) {
    const uri = `${this.apiUrl}/GetTicketById?id=${id}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<TicketVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  getTicketFilename(id: number) {
    const uri = `${this.apiUrl}/GetTicketFilename?id=${id}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<string>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          this.alertify.error("No se encontró el ticket");
          return null;
        }
      }));
  }

  private setTickets(_tickets:Ticket[]) {
    localStorage.setItem(LocalData.TICKETS, JSON.stringify(_tickets));
  }

  private getTickets(): Ticket[] {
    return JSON.parse(localStorage.getItem(LocalData.TICKETS));
  }

  saveTicket(ticketToSave: Ticket): boolean {
    try {
      let tickets: Ticket[] = this.getTickets();

      // Validate if ticket exists
      let ticket = tickets.find(item => item.TicketId === ticketToSave.TicketId);
      if (ticket) {
        let idx = tickets.indexOf(ticket);
        tickets.splice(idx, 1);
      }

      tickets.push(ticketToSave);
      this.setTickets(tickets);
      tickets = [];
      this.alertify.success(`Se guardó el ticket ${ticketToSave.TicketId}`);
      return true;
    } catch (e) {
      alert(e);
      return false;
    }
  }

  deleteTicket(ticket: Ticket) {
    let tickets: Ticket[] = this.getTickets();
    let idx = tickets.indexOf(ticket);
    tickets.splice(idx, 1);
    this.setTickets(tickets);
    tickets = [];
  }

  getAllTickets(): Ticket[] {
    return this.getTickets(); 
  }

  getSavedTicketsFromShiftId(shiftId: string) {
    const uri = `${this.apiUrl}/GetSavedTicketsFromShiftId?shiftId=${shiftId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<TicketVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
}

import { Component } from '@angular/core';

export interface GenericDialogData {
  title: string;
  message: string;
  description: string | null;
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {

  showDialog: boolean = false;
  minWidth: number = 250;
  width: number = 600;

  title: string = "";
  message: string = "";
  description: string = "";

  show(data: GenericDialogData) {
    this.title = data.title;
    this.message = data.message;
    this.description = data.description;

    this.showDialog = true;
  }

  close = () => this.showDialog = false;

}

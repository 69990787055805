//#region imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { CashClosingDetailsComponent } from '../../../../common/components/cash-closing-details/cash-closing-details.component';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { CashClosingDetailItem, ShiftCashClose } from '../../../../models/ComponentModels';
import { LocalData } from '../../../../models/LocalData';
import { CashCloseVM, CashClosingVM, LoginResponse, ShiftVM, TicketVM } from '../../../../models/view-models';
import { CashClosingService } from '../../../../services/cash-closing.service';
import { ShiftService } from '../../../../services/shift.service';
import { TicketsService } from '../../../../services/tickets.service';
import { GenericDialogComponent } from '../../../../common/components/generic-dialog/generic-dialog.component';
//#endregion

@Component({
  selector: 'single-shift-cash-close',
  templateUrl: './single-shift-cash-close.component.html',
  styleUrls: ['./single-shift-cash-close.component.scss']
})
export class SingleShiftCashCloseComponent implements OnInit {

  cashCloseList: CashCloseVM[] = [];
  selectedCashClose: CashCloseVM;
  cashClosingList: CashClosingVM[] = [];
  cashClosing: CashClosingVM;
  createDate: Date = new Date();

  @ViewChild("dateCmp", { read: CashClosingDetailsComponent })
  public dateCmp: CashClosingDetailsComponent;

  @ViewChild("salesCmp", { read: CashClosingDetailsComponent })
  public salesCmp: CashClosingDetailsComponent;

  @ViewChild("detailSalesCmp", { read: CashClosingDetailsComponent })
  public detailSalesCmp: CashClosingDetailsComponent;

  @ViewChild("cashInCmp", { read: CashClosingDetailsComponent })
  public cashInCmp: CashClosingDetailsComponent;

  @ViewChild("cashOutCmp", { read: CashClosingDetailsComponent })
  public cashOutCmp: CashClosingDetailsComponent;

  @ViewChild("returnsAndCancelationsCmp", { read: CashClosingDetailsComponent })
  public returnsAndCancelationsCmp: CashClosingDetailsComponent;

  @ViewChild("paymentsCmp", { read: CashClosingDetailsComponent })
  public paymentsCmp: CashClosingDetailsComponent;

  @ViewChild("depositsCmp", { read: CashClosingDetailsComponent })
  public depositsCmp: CashClosingDetailsComponent;

  @ViewChild("startingAndEndingCashCmp", { read: CashClosingDetailsComponent })
  public startingAndEndingCashCmp: CashClosingDetailsComponent;

  @ViewChild("cashCmp", { read: CashClosingDetailsComponent })
  public cashCmp: CashClosingDetailsComponent;

  @ViewChild("grid", { read: GenericGridComponent })
  public grid: GenericGridComponent;

  showForm: boolean = false;

  showDateDialog: boolean = false;
  public focusedDate: Date;
  public calendarDate: Date;

  showPopup: boolean = false;
  userId: string;
  userName: string;

  isShiftCashClose: boolean = false;
  showDetails: boolean = false;
  formTitle: string;

  activeShiftExists: boolean = false;
  canCloseShift: boolean = false;

  showPrintButton: boolean = false;

  @ViewChild(GenericDialogComponent) savedTicketsDialog: GenericDialogComponent;

  constructor(private cashClosingService: CashClosingService,
    private shiftService: ShiftService,
    private authService: AuthenticationService,
    private router: Router,
    private loading: LoadingService,
    private ticketService: TicketsService,
    private alertify: AlertifyService) {

    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userName = usr.User;

    this.activeShiftExists = this.shiftService.activeShiftExists();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.cashClosingService.getSingleShiftCashClose()
      .subscribe((response: CashCloseVM[]) => {
        //alert(JSON.stringify(response));
        if (response) {
          this.cashCloseList = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.cashCloseList);
    this.grid.options.toolbarTitle = "Cortes";
    this.grid.options.paging = false;
    this.grid.options.hideActionButtons = true;
    this.grid.options.showToolbar = true;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "ShiftStartDate", title: "Inicio de Turno", datetimeType: true },
      { field: "CreateDate", title: "Fecha de Corte", datetimeType: true },
      { field: "UserName", title: "Usuario" },
      { field: "TotalSales", title: "Ventas", currencyType: true },
      { field: "TotalExpenses", title: "Gastos", currencyType: true },
      { field: "StartingCash", title: "Fondo Inicial", currencyType: true },
      { field: "TotalCash", title: "Efectivo", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  loadCashClosing() {
    this.loading.show();
    this.cashClosingService.getCashClosing(this.calendarDate)
      .subscribe((response: CashClosingVM) => {
        if (response) {
          this.cashClosing = response;
          this.setDate();
          this.setSales();
          this.setDetailSales();
          this.setCashin();
          this.setCashOut();
          this.setReturnsAndCancelations();
          this.setPayments();
          this.setDeposits();
          this.setStartingAndEndingCash();
          this.setCash();

          this.setFormVisibility();

          this.loading.hide();
          this.setFormVisibility();
        }
      }, (err) => {
        this.loading.hide();
      });
  }

  async loadCashClosingFromActiveShift() {
    if (!this.activeShiftExists)
      return;

    const activeShiftHasSavedTickets = await this.ValidateActiveShiftSavedTickets();
    if (activeShiftHasSavedTickets)
      return;

    this.isShiftCashClose = true;
    this.loading.show();
    this.onToggle();
    this.cashClosingService.getCashClosingFromShift(this.shiftService.getActiveShiftId())
      .subscribe((response: CashClosingVM) => {
        if (response) {
          this.cashClosing = response;
          this.setDate();
          this.setSales();
          this.setDetailSales();
          this.setCashin();
          this.setCashOut();
          this.setReturnsAndCancelations();
          this.setPayments();
          this.setDeposits();
          this.setStartingAndEndingCash();
          this.setCash();

          this.userIsTheSame();

          this.loading.hide();
          this.setFormVisibility();
        }
      }, (err) => {
        this.loading.hide();
      });
  }

  loadCashClosingFromShift(shiftId: string) {
    this.isShiftCashClose = true;
    this.loading.show();
    this.onToggle();
    this.cashClosingService.getCashClosingFromShift(shiftId)
      .subscribe((response: CashClosingVM) => {
        if (response) {
          this.cashClosing = response;
          this.setDate();
          this.setSales();
          this.setDetailSales();
          this.setCashin();
          this.setCashOut();
          this.setReturnsAndCancelations();
          this.setPayments();
          this.setDeposits();
          this.setStartingAndEndingCash();
          this.setCash();

          //this.setFormVisibility();

          this.loading.hide();
          this.setFormVisibility();
        }
      }, (err) => {
        this.loading.hide();
      });
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  onToggle = () => this.showPopup = !this.showPopup;

  addNew() {
    this.loadCashClosing();
    this.setFormVisibility();
  }

  updateActiveShift() {
    this.loading.show();
    let activeShift: ShiftVM = JSON.parse(localStorage.getItem(LocalData.ACTIVE_SHIFT));
    activeShift.Active = false;
    this.shiftService.update(activeShift)
      .subscribe((response: ShiftVM) => {
        if (response) {
          this.isShiftCashClose = false;
          localStorage.removeItem(LocalData.ACTIVE_SHIFT);
          this.loading.hide();
          //this.router.navigate(['home']);
          window.location.reload();

        }
      }, (err) => {
        this.loading.hide();
      });
  }

  setDate() {
    if (this.showDetails) {
      this.dateCmp.detail = {
        Header: '',
        ShowHeader: false,
        ShowTotal: false,
        Total: 0,
        DetailItems: [
          {
            Title: 'Inicio de Turno',
            Value: new Date(this.selectedCashClose.ShiftStartDate),
            IsDateTime: true
          },
          {
            Title: 'Fecha de Corte',
            Value: new Date(this.selectedCashClose.CreateDate),
            IsDateTime: true
          },
          {
            Title: 'Hora de Apertura',
            Value: new Date(this.selectedCashClose.CreateDate),
            IsTime: true
          },
          { Title: 'Ventas en el día', Value: this.cashClosing.SalesCount, IsNumber: true },
        ]
      };
    } else {
      this.dateCmp.detail = {
        Header: '',
        ShowHeader: false,
        ShowTotal: false,
        Total: 0,
        DetailItems: [
          {
            Title: 'Inicio de Turno',
            Value: new Date(this.shiftService.getActiveShiftInfo().CreateDate),
            IsDateTime: true
          },
          { Title: 'Ventas en el día', Value: this.cashClosing.SalesCount, IsNumber: true },
        ]
      };
    }
  }

  setSales() {
    this.salesCmp.detail = {
      Header: 'Ventas',
      ShowHeader: true,
      ShowTotal: true,
      Total: this.cashClosing.TotalSales,
      TotalTitle: 'Total de Ventas',
      DetailItems: [
        { Title: 'En efectivo', Value: this.cashClosing.TotalSalesOnCash, IsCurrency: true },
        { Title: 'Con tarjeta', Value: this.cashClosing.TotalSalesOnCard, IsCurrency: true },
        { Title: 'A crédito', Value: this.cashClosing.TotalSalesOnCredit, IsCurrency: true },
        { Title: 'Transferencia Bancaria', Value: this.cashClosing.TotalSalesOnEBank, IsCurrency: true },
        { Title: 'Con Vale', Value: this.cashClosing.TotalSalesOnVoucher, IsCurrency: true },
      ],
      currencySales: this.cashClosing.CurrencySales
    };
  }

  setDetailSales() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.TotalSalesByProductCategories.forEach(s => {
      items.push({
        Title: s.CategoryName,
        Value: s.Total,
        IsCurrency: true
      });
      this.detailSalesCmp.detail = {
        Header: 'Ventas por Departamento',
        ShowHeader: true,
        ShowTotal: false,
        Total: 0,
        DetailItems: items
      };
    });
  }

  setCashin() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashInList.forEach(mov => {
      items.push({
        Title: mov.Concept,
        Value: mov.Amount,
        IsCurrency: true
      });
      this.cashInCmp.detail = {
        Header: 'Entradas de Efectivo',
        ShowHeader: true,
        ShowTotal: true,
        Total: this.cashClosing.TotalCashIn,
        TotalTitle: 'Total entradas',
        DetailItems: items
      };
    });
  }

  setCashOut() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashOutList.forEach(mov => {
      items.push({
        Title: mov.Concept,
        Value: mov.Amount,
        IsCurrency: true
      });
      this.cashOutCmp.detail = {
        Header: 'Salidas de Efectivo',
        ShowHeader: true,
        ShowTotal: true,
        Total: this.cashClosing.TotalCashOut,
        TotalTitle: 'Total salidas',
        DetailItems: items
      };
    });
  }

  setReturnsAndCancelations() {
    this.returnsAndCancelationsCmp.detail = {
      Header: 'Devoluciones y Cancelaciones',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: [
        { Title: 'Total devoluciones', Value: this.cashClosing.TotalReturns, IsCurrency: true },
        { Title: 'Total cancelaciones', Value: this.cashClosing.TotalCancelations, IsCurrency: true },
      ]
    };
  }

  setPayments() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CustomersPaymentsList.forEach(pay => {
      items.push({
        Title: pay.CustomerName,
        Value: pay.Amount,
        IsCurrency: true
      });
    });
    this.paymentsCmp.detail = {
      Header: 'Pagos de Clientes (liquidados)',
      ShowHeader: true,
      Total: this.cashClosing.TotalCustomerPayments,
      ShowTotal: true,
      TotalTitle: 'Total pagos',
      DetailItems: items,
      showCurrencyMovements: true,
      currencyMovements: this.cashClosing.CurrencyCustomerPayments
    };
  }

  setDeposits() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.DepositsList.forEach(deposit => {
      items.push({
        Title: deposit.CustomerName,
        Value: deposit.Amount,
        IsCurrency: true
      });
    });
    this.depositsCmp.detail = {
      Header: 'Abonos de Clientes',
      ShowHeader: true,
      Total: this.cashClosing.TotalDeposits,
      ShowTotal: true,
      TotalTitle: 'Total abonos',
      DetailItems: items,
      showCurrencyMovements: true,
      currencyMovements: this.cashClosing.CurrencyCustomerDeposits
    };
  }

  setStartingAndEndingCash() {
    this.startingAndEndingCashCmp.detail = {
      Header: 'Dinero en Caja',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: [
        { Title: 'Total de Ventas Efectivo', Value: this.cashClosing.EndingCash, IsCurrency: true },
        { Title: 'Fondo inicial', Value: this.cashClosing.StartingCash, IsCurrency: true },
      ]
    };
  }

  setCash() {
    let items: CashClosingDetailItem[] = [];
    this.cashClosing.CashEntries.forEach(item => {
      items.push({
        Title: item.Title,
        Value: item.Value,
        IsCurrency: true
      });
    });
    items.push({
      Title: "Total Entradas",
      Value: this.cashClosing.TotalRevenues,
      IsCurrency: true
    });
    items.push({
      Title: "Total Salidas",
      Value: this.cashClosing.TotalExpenses,
      IsCurrency: true
    });
    this.cashCmp.detail = {
      Header: 'Totales',
      ShowHeader: true,
      ShowTotal: false,
      Total: 0,
      DetailItems: items
    };
  }

  select(item: CashCloseVM) {
    this.showPrintButton = true;
    this.selectedCashClose = item;
    this.formTitle = "Detalles de Corte";
    this.showDetails = true;
    this.loadCashClosingFromShift(this.selectedCashClose.ShiftId);
  }

  closeDetails() {
    this.showDetails = false;
    this.showPrintButton = false;
    this.formTitle = "Crear Corte";
    this.cancel();
  }

  save() {
    this.loading.show();
    this.cashClosing.CashClose = {
      UserId: this.userId,
      UserName: this.userName,
      ShiftId: this.shiftService.getActiveShiftId(),
      TotalSales: this.cashClosing.TotalSales,
      TotalExpenses: this.cashClosing.TotalExpenses,
      TotalCash: this.cashClosing.EndingCash,
      StartingCash: this.cashClosing.StartingCash,
      //CreateDate: new Date()
    };
    this.cashClosingService.create(this.cashClosing)
      .subscribe((response: CashCloseVM) => {
        if (response) {
          response.CreateDate = new Date(response.CreateDate);
          response.ShiftStartDate = new Date(this.shiftService.getActiveShiftInfo().CreateDate);
          this.grid.addRowAtStart(response);
          this.openTicket(response.TicketUrl);
          this.cancel();
          localStorage.removeItem(LocalData.ACTIVE_SHIFT);
          this.activeShiftExists = this.shiftService.activeShiftExists();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  cancel() {
    this.setFormVisibility();
    this.showPrintButton = false;
    this.dateCmp.collapse = false;
    this.salesCmp.collapse = false;
    this.detailSalesCmp.collapse = false;
    this.cashInCmp.collapse = false;
    this.cashOutCmp.collapse = false;
    this.returnsAndCancelationsCmp.collapse = false;
    this.paymentsCmp.collapse = false;
    this.depositsCmp.collapse = false;
    this.cashCmp.collapse = false;
    this.startingAndEndingCashCmp.collapse = false;
  }

  openTicket(filename: string): void {
    const url = `${environment.cashClosingUrl}/${filename}`;
    window.open(url, '_blank');
  }

  openDateDialog() {
    this.onToggle();
    this.showDateDialog = true;
    let activeShift: ShiftVM = this.shiftService.getLocalActiveShift();
    this.focusedDate = new Date(activeShift.CreateDate);
    this.calendarDate = this.focusedDate;

  }

  onCalendarDateChange(date: Date) {
    this.calendarDate = date;
  }

  getData() {
    this.showDateDialog = false;
    this.addNew();

  }

  closeDateDialog() {
    this.showDateDialog = false;
  }

  userIsTheSame() {
    if (this.activeShiftExists) {
      if (this.shiftService.getActiveShiftUserId() === this.userId) {
        this.canCloseShift = true;
      } else {
        this.canCloseShift = false;
      }
    } else {
      this.canCloseShift = false;
    }
  }

  print() {
    this.loading.show();
    this.cashClosingService.getByIdentifier(this.selectedCashClose.UUID)
      .subscribe((response: CashCloseVM) => {
        if (response) {
          this.openTicket(response.TicketUrl);
        }
        this.loading.hide();
      });
  }

  ValidateActiveShiftSavedTickets(): Promise<boolean> {
    return new Promise((resolve) => {
      this.loading.show();
      this.ticketService.getSavedTicketsFromShiftId(this.shiftService.getActiveShiftId())
        .subscribe((response: TicketVM[]) => {
          if (response.length > 0) {
            this.savedTicketsDialog.show({
              title: 'Error al crear el corte',
              message: 'No es posible crear el corte ni cerrar el turno porque existen tickets guardados.',
              description: `Antes de crear el corte o cerrar el turno, por favor asegúrese de cerrar o eliminar los ${response.length} tickets guardados.`
            });
            this.loading.hide();
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

}
